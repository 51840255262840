import { IronSessionOptions } from 'iron-session'

export const environment = process.env.NEXT_PUBLIC_APP_ENV || 'development'
export const isProduction = environment === 'production'

export const whitelistedEmailAddresses = [
  'createsafe.io',
  'normaltechie@gmail.com',
  'roger@presolved.io'
]

export const config = {
  siweSecretKey: process.env.NEXT_PUBLIC_SIWE_SECRET_KEY
}

const FIVE_DAYS = 60 * 60 * 24 * 5

export const ironSessionOptions: IronSessionOptions = {
  password: config.siweSecretKey,
  cookieName: 'droplink-session',
  ttl: FIVE_DAYS,
  cookieOptions: {
    secure: isProduction,
    sameSite: isProduction ? 'none' : 'strict'
  }
}

// console.log('Environment:', environment)

if (!process.env.NEXT_PUBLIC_RPC_URL) throw new Error('Missing RPC URL')
export const magicConfig = {
  key: 'pk_live_E43DFD182912D5A3',
  network: isProduction
    ? {
        rpcUrl: process.env.NEXT_PUBLIC_RPC_URL,
        chainId: 137
      }
    : {
        rpcUrl: process.env.NEXT_PUBLIC_RPC_URL,
        chainId: 80002
      }
}
